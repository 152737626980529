<template lang="pug">
  #signature-capture.step-component
    h2 Your Signature

    canvas#signature-pad(ref="pad")

    .signature-footer
      button.clear(:disabled="disableButtons || signatureSaving" @click="clearSignature") Clear
      VerificationButton.done(:disabled="disableButtons" @click.native="saveSignature" :state="buttonState" text="Done")

</template>

<script>
import SignaturePad from 'signature_pad'
import { transparentPng } from '@/utils/index'

export default {
  props: {},

  data() {
    return {
      signaturePad: null,
      canClear: false,
      signatureAdded: false,
      signatureSaving: false,
      signatureData: transparentPng,
      onResizeHandler: null,
      buttonState: 'init',
    }
  },

  beforeCreate() {
    this.$app.forceLandscape = true
  },

  mounted() {
    this.signaturePad = new SignaturePad(this.$refs.pad, {
      minWidth: 2,
      maxWidth: 4,
      penColor: '#000000',
      backgroundColor: '#FFFFFF',
    })

    this.onResizeHandler = this.resizeCanvas
    window.addEventListener('resize', this.onResizeHandler, false)

    setTimeout(() => {
      this.resizeCanvas()
    })
  },

  beforeDestroy() {
    if (this.onResizeHandler) {
      window.removeEventListener('resize', this.onResizeHandler, false)
    }
  },

  computed: {
    disableButtons,
    needToForceLandscape,
  },

  watch: {
    '$breakpoint.orientation': function(newOrientation, oldOrientation) {
      if (oldOrientation && newOrientation != oldOrientation) {
        this.resizeCanvas()
      }
    },
  },

  methods: {
    clearSignature,
    resizeCanvas,
    saveSignature,
  },

  components: {
    VerificationButton: require('@/components/VerificationButton').default,
  },
}

/* Computed ---------------------------------------------------- */
function disableButtons() {
  return this.signaturePad?.isEmpty()
}

function needToForceLandscape() {
  return this.$app.forceLandscape && this.$breakpoint.orientation == 'portrait'
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function clearSignature() {
  this.signaturePad?.clear()
}

function resizeCanvas() {
  const canvas = this.$refs.pad
  const ctx = canvas.getContext('2d')
  const data = this.signaturePad.toData()
  const ratio = Math.max(window.devicePixelRatio || 1, 1)

  canvas.width = canvas.offsetWidth * ratio
  canvas.height = canvas.offsetHeight * ratio

  if (this.needToForceLandscape) {
    ctx.rotate(1.5 * Math.PI)
    ctx.translate(-canvas.height, 0)
  }

  ctx.scale(ratio, ratio)

  this.clearSignature()
  this.signatureData = transparentPng
  this.signaturePad.fromData(data)
}

function saveSignature() {
  this.signatureSaving = true
  this.buttonState = 'try'

  // Generate consent PDF with signature appended
  this.$refs.pad.toBlob(blob => {
    this.$store.dispatch('user/uploadSignature', {
      file: blob,
      fileName: `signature-${Date.now()}.png`,
    })
  }, 'image/png')

  this.buttonState = 'success'
  setTimeout(() => {
    this.$emit('complete')
  }, 1000)
}
</script>
